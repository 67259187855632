<template>
    <div>
        <h1 class="page__title">FAQ</h1>
        <div class="actions__container">
            <router-link class="btn btn-primary w-40 inline-block mr-1 mb-2" to="/faq/create">Создать</router-link>
        </div>

        <ContentSearch />

        <ContentTable
            :inverse-toggle-icons="true"
            :table-actions="tableActions"
            :headers="tableHeaders"
            :table-rows="tableData"
            :meta="pagination"
            :loading="loading"
            @edit="editAction"
            @delete="deleteAction"
        />

        <CrudModal
            :isOpen="modalOpen"
            :title="modalTitle"
            :type="modalType"
            :accept-callback="modalCallback"
            :modal-data="modalData"
            @update:isOpen="modalOpen = $event"
            @deleteModalAccepted="onDeleteModalAccepted"
        />
    </div>
</template>

<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import ContentTable from '@/components/tables/ContentTable';
import CrudModal from '@/components/modals/CrudModal';
import ContentSearch from '@/components/base/ContentSearch';

export default {
    components: { ContentSearch, CrudModal, ContentTable },
    mixins: [errorResponse],
    data() {
        return {
            loading: false,
            affiliate: false,
            modalComponent: () => import('@/components/modals/ModalDelete'),
            modalOpen: false,
            modalType: null,
            modalTitle: null,
            modalCallback: null,
            modalData: null,
            tableActions: [
                { name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                { name: 'delete', title: 'удалить', class: 'bg-red-400', icon: 'TrashIcon', cbName: 'delete' },
            ],
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'name', label: 'Название' },
            ],
            tableData: [],
            pagination: null,
            affiliates: [],
        };
    },
    async created() {
        await this.fetchData(this.$route.query);
    },
    methods: {
        async fetchData(params) {
            try {
                this.loading = true;
                const { data } = await this.axios.get('/faqs', { params });
                this.tableData = data.data;
                this.pagination = data.meta;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.error('cant fetch data: ' + ex);
            }
        },
        editAction(row) {
            this.$router.push({ name: 'faqEdit', params: { id: row.id } });
        },
        deleteAction(row) {
            this.modalData = row;
            this.modalType = 'delete';
            this.modalOpen = true;
        },
        onDeleteModalAccepted(data) {
            this.axios
                .delete('/faqs/' + data.id)
                .then((res) => {
                    this.fetchData(this.$route.query);
                    this.modalOpen = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
    },
    async beforeRouteUpdate(to, from, next) {
        await this.fetchData({ ...to.query });
        next();
    },
};
</script>

<style></style>
