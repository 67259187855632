<template>
  <div
    v-if="isOpen"
    class="my-modal fixed flex justify-center items-center w-full h-full left-0 top-0"
  >
    <Preloader v-if="loading" />

    <template v-else>
      <ModalDelete
        v-if="type === 'delete'"
        :isOpen="isOpen"
        :modal-data="modalData"
        @modalClose="closeModal"
        @modalAccepted="modalAccepted"
        :title="title || 'Удалить?'"
        :accept-function="acceptCallback"
      />

      <!--    <CreateUpdateModal-->
      <!--      v-if="type === 'update' || type === 'create'"-->
      <!--      :isOpen="isOpen"-->
      <!--      @modalClose="closeModal"-->
      <!--      @modalAccepted="modalAccepted"-->
      <!--      :title="title"-->
      <!--      :accept-function="acceptCallback"-->
      <!--      :modal-data="modalData"-->
      <!--    />-->
    </template>
  </div>
</template>

<script>
import Preloader from '@/components/preloader/Main';
import CreateUpdateModal from "./CreateUpdateModal";
import ModalDelete from "./ModalDelete";

export default {
  name: "CrudModal",
  components: {Preloader, CreateUpdateModal, ModalDelete},
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    type: {
      type: null,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    modalData: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('update:isOpen', false)
    },
    modalAccepted(data) {
      if (this.type === 'delete') {
        this.$emit(`${this.type}ModalAccepted`, this.modalData)
      } else {
        this.$emit(`${this.type}ModalAccepted`, data)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.my-modal{
  background: rgba(#000, .5);
  z-index: 10000;
}
</style>
